/* Responsive Css */


@media only screen and (max-width: 1200px){

  .main-menu,
  .sticky-header,
  .main-header.style-one .outer-container:before{
    display: none !important;
  }

  .menu-area .mobile-nav-toggler {
    display: block;
    padding: 10px;
  }

  .megamenu ul li:first-child{
    display: none;
  }

  .header-style-one{
    top: 30px;
  }

  .header-style-three .header-lower{
    padding: 15px 0px;
  }



}



@media only screen and (min-width: 768px){
  .main-menu .navigation > li > ul,
  .main-menu .navigation > li > ul > li > ul,
  .main-menu .navigation > li > .megamenu{
    display:block !important;
    visibility:hidden;
    opacity:0;
  }
}



@media only screen and (max-width: 991px){

  .main-footer .logo-widget p{
    max-width: 100%;
  }

  .main-footer .logo-widget{
    margin-bottom: 30px;
  }

}


@media only screen and (max-width: 767px){

  .sec-title h2{
    font-size: 36px;
    line-height: 46px;
  }

  .main-footer .footer-widget{
    margin: 0px 0px 30px 0px !important;
  }

  .main-footer .widget-section{
    padding: 80px 0px 50px 0px;
  }
}

@media only screen and (max-width: 599px){



}


@media only screen and (max-width: 499px){

  .mobile-menu{
    width: 100%;
  }

  .menu-right-content{
    display: none;
  }

  .menu-right-in-list {
    display: block;
  }

  .pagination li a{
    /* width: 40px; */
    /* height: 40px; */
    line-height: 40px !important;
  }


}



.cs-hero_social_wrap {
    background-color: rgba(0, 0, 0, 0.5);
    margin-right: 10px;
    padding-right: 8px;
    padding-left: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 20px;
    transform: rotate(0deg) translateY(-85%) !important;
    right: 0px;
    display: flex;
    position: absolute;
    top: 50%;
}

.cs-hero_social_links li {
  display: flex;
  align-items: center;
  justify-content: center;
}