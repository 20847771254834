 
/* Css For Mirada AI */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two
5. Main Slider/style-one/style-two
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Testimonial Section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section
39. Google Map


**********************************************/



/*** 

====================================================================
  Reset
====================================================================

 ***/
* {
  margin:0px;
  padding:0px;
  border:none;
  outline:none;
}


/*** 

====================================================================
  Global Settings
====================================================================

 ***/

@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Nunito:300,400,400i,600,700);


:root{
  --theme-color: rgba(255, 29, 29, 1);
  --secondary-color: rgba(255, 151, 118, 1);
  --text-color: rgba(106, 106, 106, 1);
  --title-color: #273238; 
  /* rgba(17, 17, 17, 1); */
  --text-font: 'Nunito', sans-serif;
  --title-font: 'Nunito', sans-serif;
}





body {
  font-size:16px;
  color: var(--text-color);
  line-height:28px;
  font-weight:400;
  background:#ffffff;
  font-family: var(--text-font);
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center top;
  -webkit-font-smoothing: antialiased;
}

  @media (min-width:1200px) {
  .container {
    max-width: 1200px;
    padding: 0px 15px;
  }
}

.large-container{
  max-width: 1550px;
  padding: 0px 15px;
  margin: 0 auto;
}

.container-fluid{
  padding: 0px;
}

.auto-container{
  position:static;
  max-width:1320px;
  padding:0px 15px;
  margin:0 auto;
}

.small-container{
  max-width:680px;
  margin:0 auto;
}

.boxed_wrapper{
  position: relative;
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}


a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

a:hover{
  text-decoration:none;
  outline:none;
}

input,button,select,textarea{
  font-family: var(--text-font);
  font-weight: 400;
  font-size: 18px;
  background: transparent;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}

input{
  transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}

p{
  position: relative;
  font-family: var(--text-font);
  color: var(--text-color);
  font-weight: 400;
  margin: 0px;
  transition: all 500ms ease;
}

h1,h2,h3,h4,h5,h6{
  position:relative;
  font-family: var(--title-font);
  font-weight: 400;
  color: var(--title-color);
  margin: 0px;
  transition: all 500ms ease;
}

/* Preloader */

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  background: rgba(2, 2, 2, 1);
}

.preloader-close{
  position: fixed;
  z-index: 99999999;
  font-size: 14px;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  top: 30px;
  font-weight: 400;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner{
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px auto;
  width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before{
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top:0;
  position: absolute;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading{
  font-family: var(--title-font);
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.30);
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {animation-delay: 0.2s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {animation-delay: 0.4s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {animation-delay: 0.6s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {animation-delay: 0.8s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before { animation-delay: 1s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before { animation-delay: 1.2s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before { animation-delay: 1.4s;}
.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(9):before { animation-delay: 1.6s;}
.handle-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before{
  color: #ffffff;
}

.handle-preloader .animation-preloader .spinner{
  border: 3px solid #ffffff;
  border-top-color: rgba(255, 255, 255, 0.5); 
}

/* AnimaciÃ³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}
@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }
  .handle-preloader .animation-preloader .txt-loading .letters-loading {font-size: 40px; letter-spacing: 10px;}
}


.centred{
  text-align: center;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}

.special_fonts{
  font-family: 'Fredoka', sans-serif;
}


figure{
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

.row{
  --bs-gutter-x: 30px;
}

/** button **/


.theme-btn{
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-family: var(--title-font);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  z-index: 1;
  transition: all 500ms ease;
}

.theme-btn:hover{
  color: #fff !important;
}

.theme-btn.btn-one{
  background: var(--theme-color);
}

.theme-btn i{
  position: relative;
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  top: 0px;
}

.theme-btn:before, .theme-btn:after {
  content: "";
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0%;
  background: #F86868;
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: center;
  transform: scale(0) rotate(0);
  z-index: -1;
}

.theme-btn:hover:before {
  border-radius: 0;
  transform: scale(1) rotate(-180deg);
}

.theme-btn:hover:after {
  border-radius: 0;
  transform: scale(1) rotate(180deg);
}

.theme-btn:after {
  background: #F86868;
}

.theme-btn.btn-two{
  background: var(--secondary-color);
  color: #000;
}

.theme-btn.btn-two span:before,
.theme-btn.btn-two span:after{
  background: var(--secondary-color);
}

.theme-btn span{
  position: relative;
  display: inline-block;
  padding: 10px 20px;
}

.theme-btn span:before{
  position: absolute;
  content: '';
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  left: -10px;
  top: -10px;
  border-radius: 50%;
  transform: scale(0,0);
  transition: all 500ms ease;
}

/* .theme-btn:hover span:before{
  transform: scale(1,1);
} */

.theme-btn span:after{
  position: absolute;
  content: '';
  background: var(--theme-color);
  width: 30px;
  height: 30px;
  right: -10px;
  bottom: -10px;
  border-radius: 50%;
  transform: scale(0,0);
  transition: all 500ms ease;
}

/* .theme-btn:hover span:after{
  transform: scale(1,1);
} */

.theme-btn.btn-three span{
  padding: 17px 30px;
}

.parallax-bg{
  position: absolute;
  left: 0px;
  top: -30%;
  width: 100%;
  height: calc(100% + 30%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


/** styled-pagination */

.pagination{
  position: relative;
  display: block;
}

.pagination li{
  position: relative;
  display: inline-block;
  margin: 0px 5px;
}

.pagination li a{
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--title-font);
  height: 60px;
  width: 60px;
  line-height: 60px;
  background: #ffffff;
  box-shadow: 0px 10px 40px 10px rgba(0, 0, 0, 0.07);
  text-align: center;
  color: #000;
  border-radius: 15px;
  z-index: 1;
  transition: all 500ms ease;
}

.pagination li a:hover,
.pagination li a.current{
  color: #fff;
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.pagination li:first-child a,
.pagination li:last-child a{
  border-radius: 50%;
  font-size: 16px;
  line-height: 64px;
}

.sec-pad{
  padding: 142px 0px 150px 0px;
}

.mr-0{
  margin: 0px !important;
}

/** scroll-to-top **/

.scroll-to-top{
  position: fixed;
  right: 0px;
  bottom: 100px !important;
  transform: rotate(90deg);
  z-index: 99;
}

.scroll-to-top .visible {
  visibility: visible!important;
  opacity: 1!important;
}

.scroll-to-top .scroll-top-inner {
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top .scroll-top-inner{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
  -o-transition: all cubic-bezier(.4,0,.2,1) .4s;
  transition: all cubic-bezier(.4,0,.2,1) .4s;
}

.scroll-to-top .scroll-bar {
  width: 50px;
  height: 2px;
  margin-right: 10px;
  position: relative;
}

/* .scroll-to-top .scroll-bar:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #f4244f;
  opacity: .3;
} */

.scroll-to-top .scroll-bar .bar-inner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: currentColor;
}

.scroll-to-top .scroll-bar-text{
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  color: var(--theme-color);
  transition: all 500ms ease;
}

.light-home .scroll-to-top .scroll-bar-text{
  color: var(--secondary-color);
}

.scroll-to-top .scroll-bar-text:hover{
  transform: scale(1.1);
}



@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}



.sec-title{
  position: relative;
  display: block;
}

.sec-title h2{
  position: relative;
  display: block;
  font-size: 26px;
  line-height: 58px;
  font-weight: 400;
  text-transform: capitalize;
  margin: 0px;
}

.sec-title.light h2{
  color: #fff !important;
}

.sub-title{
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 0px 16px;
  text-transform: uppercase;
  margin-bottom: 20px;
  background: rgba(255, 29, 29, 0.05);
  border: 1px solid rgba(255, 29, 29, 0.2);
}

.light-home .sub-title{
  background: rgba(255, 151, 118, 0.10);
  border: 1px solid rgba(255, 151, 118, 0.15);
}

.sub-title.two{
  background: rgba(255, 29, 29, 0.12);
  border: transparent;
  padding-top: 1px;
  padding-bottom: 1px;
}



.boxed_wrapper.light-home{
  background: rgba(2, 2, 2, 1);
}





/*** 

====================================================================
                        Home-Page-One
====================================================================

***/


/** main-header **/

.main-header{
  position:relative;
  left:0px;
  top:0px;
  right: 0px;
  z-index:999;
  width:100%;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.sticky-header{
  position:fixed;
  opacity:0;
  visibility:hidden;
  left:0px;
  top:0px;
  width:100%;
  z-index:0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.fixed-header .sticky-header{
  z-index:999;
  opacity:1;
  visibility:visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}

.main-header .outer-box{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-right-in-list {
  display: none;
  text-decoration: underline;
}

.menu-right-content{
  position: relative;
  display: flex;
  align-items: center;
}

.menu-right-content .login-btn{
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 26px;
  font-family: var(--title-font);
  font-weight: 500;
  color: #111111;
}

.light-home .menu-right-content .login-btn{
  color: #fff;
}

.boxed_wrapper.light-home .menu-right-content .login-btn:hover{
  color: var(--secondary-color);
}

.menu-right-content .login-btn:hover{
  color: var(--theme-color);
}

.menu-right-content .theme-btn span{
  padding: 10px 25px;
}

.header-style-one{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}


/** main-menu **/

.main-menu{
  float: left;
}

.main-menu .navbar-collapse{
  padding:0px;
  display:block !important;
}

.main-menu .navigation{
  margin:0px;
}

.main-menu .navigation > li{
  position:inherit;
  float:left;
  z-index:2;
  margin: 0px 20px;
  -webkit-transition:all 300ms ease;
  -moz-transition:all 300ms ease;
  -ms-transition:all 300ms ease;
  -o-transition:all 300ms ease;
  transition:all 300ms ease;
}

.main-menu .navigation > li:last-child{
  margin-right:0px !important;
}

.main-menu .navigation > li:first-child{
  margin-left: 0px !important;
}

.main-menu .navigation > li > a{
  position:relative;
  display:block;
  text-align:center;
  font-size:18px;
  line-height:30px;
  padding-top: 35px;
  padding-bottom: 35px;
  font-weight:600;
  font-family: var(--title-font);
  opacity:1;
  color: #111111;
  z-index:1;
  -webkit-transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  transition:all 500ms ease;
}

.light-home .main-menu .navigation > li > a{
  color: #fff;
}

.main-menu .navigation > li.current > a,
.main-menu .navigation > li:hover > a{
  
}

.main-menu .navigation > li > ul,
.main-menu .navigation > li > .megamenu{
  position:absolute;
  left: inherit;
  top:100%;
  width:250px;
  margin-top: 15px;
  z-index:100;
  display:none;
  background: rgba(2, 2, 2, 1);
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul.from-right{
  left:auto;
  right:0px;  
}

.main-menu .navigation > li > ul > li{
  position:relative;
  width:100%;
}

.main-menu .navigation > li > ul > li > a,
.main-menu .navigation > li > .megamenu li > a{
  position:relative;
  display:block;
  padding: 10px 25px;
  line-height:30px;
  font-weight:500;
  font-size:16px;
  text-transform:capitalize;
  font-family: var(--title-font);
  color:#fff;
  text-align: left;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > a{
  border-bottom: 1px solid rgba(255,255,255,0.1);
}

.main-menu .navigation > li > .megamenu li > a{
  padding-left: 0px;
}

.main-menu .navigation > li > .megamenu h4{
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.main-menu .navigation > li > ul > li > a:hover,
.main-menu .navigation > li > .megamenu li > a:hover{
  padding-left: 35px;
}

.main-menu .navigation > li > ul > li:last-child > a,
.main-menu .navigation > li > .megamenu li:last-child > a{
  border-bottom: none;
}

.main-menu .navigation > li > ul > li.dropdown > a:after{
  font-family: 'Font Awesome 6 Free';
  content: "\f105";
  position:absolute;
  right:20px;
  top:10px;
  display:block;
  line-height:24px;
  font-size:16px;
  font-weight:800;
  text-align:center;
  z-index:5;  
}

.main-menu .navigation > li > ul > li > ul{
  position:absolute;
  left:100%;
  top:0%;
  margin-top: 15px;
  background: rgba(2, 2, 2, 1);
  width:250px;
  z-index:100;
  display:none;
  border-radius: 0px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul.from-right{
  left:auto;
  right:0px;  
}

.main-menu .navigation > li > ul > li > ul > li{
  position:relative;
  width:100%;
}

.main-menu .navigation > li > ul > li > ul > li:last-child{
  border-bottom:none; 
}

.main-menu .navigation > li > ul > li > ul > li > a{
  position:relative;
  display:block;
  padding:10px 25px;
  line-height:24px;
  font-weight:500;
  font-size:16px;
  text-transform:capitalize;
  font-family: var(--title-font);
  color:#fff;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  text-align: left;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

.main-menu .navigation > li > ul > li > ul > li:last-child > a{
  border-bottom: none;
}

.main-menu .navigation > li > ul > li > ul > li > a:hover{
  padding-left: 35px;
}

.main-menu .navigation > li > ul > li > ul > li.dropdown > a:after{
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";
  position:absolute;
  right:20px;
  top:12px;
  display:block;
  line-height:24px;
  font-size:16px;
  font-weight:900;
  z-index:5;  
}

.main-menu .navigation > li.dropdown:hover > ul,
.main-menu .navigation > li.dropdown:hover > .megamenu{
  visibility:visible;
  opacity:1;
  margin-top: 0px;
  top: 100%;
}

.main-menu .navigation li > ul > li.dropdown:hover > ul{
  visibility:visible;
  opacity:1;
  top: 0%; 
  margin-top: 0px;
}

.main-menu .navigation li.dropdown .dropdown-btn{
  position:absolute;
  right:-32px;
  top:66px;
  width:34px;
  height:30px;
  text-align:center;
  font-size:18px;
  line-height:26px;
  color:#3b3b3b;
  cursor:pointer;
  display: none;
  z-index:5;
  transition: all 500ms ease;
}

.main-menu .navigation li.current.dropdown .dropdown-btn,
.main-menu .navigation li:hover .dropdown-btn{
  
}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn{
  display: none;
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  background: var(--theme-color);
  display: none;
  border-radius: 13px;
}

.mobile-menu .nav-logo img{
  max-width: 160px;
}

.menu-area .mobile-nav-toggler .icon-bar{
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #fff;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child{
  margin-bottom: 0px;
}


/** megamenu-style **/

.main-menu .navigation > li.dropdown > .megamenu{
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li h4{
  margin-bottom: 10px;
}

.sticky-header .main-menu .navigation > li > a{
  padding-top: 25px;
  padding-bottom: 25px;
  color: #000;
}

.sticky-header .main-menu .navigation > li.dropdown > a:before{
  top: 27px;
}

.sticky-header  .main-menu:before{
  top: 15px;
}


/** mobile-menu **/

.nav-outer .mobile-nav-toggler{
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color:#3786ff;
  display: none;
}

.mobile-menu{
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right:30px;
  max-width:100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 900ms ease;
}

.mobile-menu .navbar-collapse{
  display:block !important; 
}

.mobile-menu .nav-logo{
  position:relative;
  padding:50px 25px;
  text-align:left;  
  padding-bottom: 100px;
}

.mobile-menu-visible{
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu{
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop{
  position: fixed;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 900ms ease;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop{
  opacity: 0.70;
  visibility: visible;
  right: 100%;
  -webkit-transition: all .8s ease-out 0s;
  -o-transition: all .8s ease-out 0s
}

.mobile-menu .menu-box{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #141417;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 900ms ease !important;
}

.mobile-menu-visible .mobile-menu .menu-box{
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn{
  position: absolute;
  right: 25px;
  top: 10px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition:all 0.9s ease;
  -moz-transition:all 0.9s ease;
  -ms-transition:all 0.9s ease;
  -o-transition:all 0.9s ease;
  transition:all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn{
  -webkit-transform:rotate(360deg);
  -ms-transform:rotate(360deg);
  transform:rotate(360deg);
}

.mobile-menu .close-btn:hover{
  -webkit-transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  transform:rotate(90deg);
}

.mobile-menu .navigation{
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li{
  position: relative;
  display: block;
  border-top: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation:last-child{
  border-bottom: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation li > ul > li:first-child{
  border-top: 1px solid rgba(255,255,255,0.10);
}

.mobile-menu .navigation li > a{
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
}

.mobile-menu .navigation li ul li > a{
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li > a:before{
  content:'';
  position:absolute;
  left:0;
  top:0;
  height:0;
  border-left:5px solid #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
}

.mobile-menu .navigation li.current > a:before{
  height:100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn{
  position:absolute;
  right:6px;
  top:6px;
  width:32px;
  height:32px;
  text-align:center;
  font-size:16px;
  line-height:32px;
  color:#ffffff;
  background:rgba(255,255,255,0.10);
  cursor:pointer;
  border-radius:2px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
  z-index:5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open{
  color: #ffffff;
  -webkit-transform:rotate(90deg);
  -ms-transform:rotate(90deg);
  transform:rotate(90deg);  
}

.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul,
.mobile-menu .navigation > li.dropdown > .megamenu{
  display: none;
}

.mobile-menu .social-links{
  position:relative;
  padding:0px 25px;
}

.mobile-menu .social-links li{
  position:relative;
  display:inline-block;
  margin:0px 10px 10px;
}

.mobile-menu .social-links li a{
  position:relative;
  line-height:32px;
  font-size:16px;
  color:#ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease; 
}

.mobile-menu .social-links li a:hover{
  
}

div#mCSB_1_container{
  top: 0px !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 120px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255,255,255,0.80);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a{
  color: rgba(255,255,255,0.80);
}

.mobile-menu .contact-info ul li a:hover{
  
}

.mobile-menu .contact-info ul li:last-child{
  margin-bottom: 0px;
}

.main-header .outer-box{
  position: relative;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav{
  display: none !important;
}

.owl-nav button{
  background: transparent;
}

.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.float-bob-x{
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}


/** rtl-switcher **/

.demo-rtl{
  position: fixed;
  top: 390px;
  left: 10px;
  z-index: 9999;
}

button.rtl{
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 700;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms ease;
}

.light-home button.rtl{
  background: var(--secondary-color);
}

.demo-ltr{
  position: fixed;
  top: 390px;
  left: auto;
  right: 10px;
  z-index: 9999;
}

button.ltr {
  background: var(--theme-color);
  display: block;
  text-indent: inherit;
  font-size: 12px;
  font-weight: 700;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0px;
  color: #fff !important;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 1.0);
  transition: all 500ms ease;
}

.light-home button.ltr{
  background: var(--secondary-color);
}

.boxed_wrapper.ltr .demo-rtl{
  display: block;
}

.boxed_wrapper.ltr .demo-ltr{
  display: none;
}

.boxed_wrapper.rtl .demo-rtl{
  display: none;
}

.boxed_wrapper.rtl .demo-ltr{
  display: block;
}

.list-style-one{
  position: relative;
  display: block;
}

.list-style-one li{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #0D0D0D;
  font-weight: 500;
  padding-left: 29px;
  margin-bottom: 15px;
}

.list-style-one li:last-child{
  margin-bottom: 0px;
}

.list-style-one li:before{
  position: absolute;
  content: '\e912';
  font-family: 'icomoon';
  font-size: 16px;
  color: #00C767;
  left: 0px;
  top: 4px;
  font-weight: 400;
}

.accordion-box .block .acc-content{
  display:none;
}

.accordion-box .block .acc-content.current{
  display:block;  
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}


/** main-footer **/

.main-footer{
  position: relative;
  background: #020202;
}

.main-footer .widget-section{
  padding: 120px 0px 100px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.main-footer .logo-widget .footer-logo{
  margin-bottom: 30px;
}

.main-footer .logo-widget p{
  max-width: 500px;
  color: #A9A7B0;
  margin-bottom: 40px;
}

.main-footer .logo-widget .social-links li{
  position: relative;
  display: inline-block;
  margin-right: 10px;
  float: left;
}

.main-footer .logo-widget .social-links li:last-child{
  margin: 0px !important;
}

.main-footer .logo-widget .social-links li a{
  position: relative;
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.30);
  border: 1px solid rgba(255, 255, 255, 0.10);
  border-radius: 50%;
  text-align: center;
}

.main-footer .logo-widget .social-links li a:hover{

}

.main-footer .widget-title{
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.main-footer .widget-title h3{
  font-size: 24px;
  line-height: 32px;
  color: #fff;
}

.main-footer .links-widget .links-list li{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 42px;
}

.main-footer .links-widget .links-list li a{
  display: inline-block;
  color: #A9A7B0;
}

.main-footer .links-widget .links-list li a:hover{

}

.main-footer .contact-widget p{
  color: #A9A7B0;
  margin-bottom: 20px;
}

.main-footer .contact-widget .info-list li{
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.main-footer .contact-widget .info-list li:last-child{
  margin-bottom: 0px;
}

.main-footer .contact-widget .info-list li a{
  display: inline-block;
  color: #A9A7B0;
}

.main-footer .contact-widget .info-list li a:hover{

}

.main-footer .contact-widget .widget-title{
  margin-bottom: 25px;
}

.footer-bottom{
  position: relative;
  width: 100%;
  padding: 30px 0px;
}

.footer-bottom p{
  line-height: 26px;
  color: #A9A7B0;
}

.footer-bottom p a:hover{
  text-decoration: underline;
}

.main-footer .contact-widget .contact-info li{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 40px;
  color: #A9A7B0;
  padding-left: 26px;
}

.main-footer .contact-widget .contact-info li a{
  display: inline-block;
  color: #A9A7B0;
}

.main-footer .contact-widget .contact-info li a:hover{

}

.main-footer .contact-widget .contact-info li i{
  position: absolute;
  left: 0px;
  top: 10px;
  font-size: 20px;
}

.main-footer .contact-widget .contact-info li:nth-child(2) i{
  font-size: 14px;
  top: 15px;
}

.main-footer.style-two .widget-section{
  border-bottom: none;
}

.main-footer.style-two .footer-bottom{
  background: #111111;
}


/** header-style-two **/

.header-style-two{
  position: absolute;
  left: 0px;
  top: 30px;
  width: 100%;
}

.header-style-two .header-lower .outer-box{
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 0px 20px 0px 30px;
}

.header-style-two .header-lower .main-menu .navigation > li > a{
  padding-top: 30px;
  padding-bottom: 30px;
}

.header-style-three{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.header-style-three .header-lower{
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.white-bg{
  background: #fff !important;
}

.tabs-box .tab{
  position:relative;
  display:none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab{
  display:block;  
}

.tabs-box .tab{
  transform:scale(0.9,0.9) translateY(0px);
}

.tabs-box .tab.active-tab{
  transform:scale(1) translateY(0px);
}

.boxed_wrapper.home-7{
  background-color: #FAFDFF;
}

.light-home .menu-area .mobile-nav-toggler{
  background: var(--secondary-color);
}

.default-form label{
  font-weight: 500;
  color: #000;
}

.default-form input[type='text'],
.default-form input[type='email'],
.default-form input[type='tel'],
.default-form input[type='password'],
.default-form textarea{
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  font-size: 15px;
  color: #6E6E6E;
  padding: 15px 20px;
  border-radius: 0px;
  background: transparent;
  border: 1px solid #D5D5D5;
  transition: all 500ms ease;
  border-radius: 5px;
}

.default-form input:focus,
.default-form textarea:focus{

}

.shop-details .product-discription .comment-form textarea {
  height: 170px;
  resize: none;
}


.bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-down{
  position: absolute;
  background: transparent;
  padding: 0px 0px;
  left: -19px;
  top: 7px;
  border-radius: 0px;
  border: none !important;
  z-index: 1;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn.bootstrap-touchspin-up{
  position: absolute;
  background: transparent;
  padding: 0px 0px;
  right: -23px;
  top: 6px;
  border: none !important;
  margin-top: 0px;
  border-radius: 0px;
  z-index: 1;
}

.bootstrap-touchspin .glyphicon-chevron-up:before {
  content: "+";
  font-size: 24px;
  font-style: normal;
  color: #6A6A6A;
  font-weight: 400;
  transition: all 500ms ease;
}

.bootstrap-touchspin .glyphicon-chevron-down:before {
  content: "-";
  font-size: 24px;
  font-style: normal;
  color: #6A6A6A;
  font-weight: 400;
  transition: all 500ms ease;
}

.bootstrap-touchspin .glyphicon-chevron-up:hover:before,
.bootstrap-touchspin .glyphicon-chevron-down:hover:before{
  color: #101010;
}

.bootstrap-touchspin .input-group-btn-vertical{
  position: absolute;
  width: 100%;
}

.bootstrap-touchspin .input-group-btn-vertical i{
  top: 0px;
  left: 7px;
  font-size: inherit !important; 
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down{
  margin-top: 0px;
}

@-moz-keyframes scroll-left {
  0% { -moz-transform: translateX(0%); }
  100% { -moz-transform: translateX(-100%); }
}

@-webkit-keyframes scroll-left {
  0% { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
}


.intro-client {
  max-width: 120px;
  max-height: 30px;
}

body {
  scrollbar-width: none;
}

.ai-gradient-color {
  background: -webkit-linear-gradient(-180deg, #D12020, #4796E3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.platform-image-front {
  max-width: 320px;
}

.platform-image-back {
  max-width: 320px;
}
.platform-image-mobile {
  max-height: 340px;
  width: auto !important;
}

.icon-background-gradient-override {
  background: -webkit-linear-gradient(-90deg, #ffd7da, #D12020);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.logo-size-override-footer {
  max-width: 200px;
}

.logo-size-override-header {
  max-width: 200px;
}

.sentinel-logo {
  width: 120px;
  right: 60px !important;
  bottom: -20px !important;
}
.modal-content {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 100%;
  width: 100%;
}

/* This will allow the body to scroll independently of the footer */
.modal-body {
  flex: 1; /* This makes the body take up available space */
  overflow-y: auto; /* Enable scrolling for the modal body */
}

/* Ensure that the footer stays at the bottom */
.modal-footer {
  margin-top: auto; /* Push the footer to the bottom */
  padding-bottom: 30px;
}

/* Your existing styles... */
.modal-dialog {
  max-width: 800px;
  height: 99vh;
  overflow: hidden; /* Prevent overflow on the dialog */
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

#myModal .modal-content {
  border: none !important;
}

div#myModal {
  background: #0000006e;
  overflow: hidden; /* Prevent overflow on the backdrop */
}

h4.modal-title {
  font-size: 20px;
  text-align: left;
}

.modal-header {
  text-align: left;
  justify-content: flex-start;
}

button.btn.theme-btn.copy-remove {
  padding-top: 13px !important;
  font-size: 15px !important;
  padding: 8px 32px;
  background: var(--theme-color) !important;
  border: 1px solid #ff3a72;
  color: #ffffff;
  text-transform: uppercase;
}

.modal-body {
  font-size: 15px;
  color: black;
}

body.modal-open {
  overflow: hidden;
  height: 99vh;
  position: fixed;
  width: 100%;
}

/* Styling the scrollbar */
.modal-body::-webkit-scrollbar {
  width: 12px; /* Set scrollbar width */
}

/* Track styling (background of scrollbar) */
.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 0 10px 10px 0; /* Rounded only on the right side */
}

/* Thumb (draggable part) styling */
.modal-body::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 10px; /* Rounded scrollbar thumb */
  border: 3px solid #f1f1f1; /* Padding around thumb */
}

/* Thumb hover effect */
.modal-body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade on hover */
}

.modal-body h3 {
  margin-top: 20px; /* Adds spacing above the header */
  font-size: 18px;
  color: #333;
}

.modal-footer button {
  text-transform: capitalize !important;
}