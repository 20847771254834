.chat-box{
		height: 80%;
    width: 400px;
    position: fixed;
    margin: 0 auto;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 9999998;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.005);
    right: 30px;
    bottom: 0;
    margin: 15px;
		background: #fff;
    box-shadow: 5px 1px 10px rgba(0, 0, 0, 0.3);
		border-radius: 15px;
    visibility: hidden;
	
		&-header{
			height: 8%;
			border-top-left-radius: 15px;
			border-top-right-radius: 15px;
			display: flex;
			align-items: center;
			font-size: 14px;
			padding: .5em 0;
    	box-shadow: 0 0 3px rgba(0,0,0,.2);
    	box-shadow:0 0 3px rgba(0,0,0,.2), 0 -1px 10px 				rgba(172, 54, 195, 0.3);
			box-shadow: 0 1px 10px rgba(0,0,0,0.025);
			& h3{
				font-weight: 400;
				float: left;
   		 	position: absolute;
    		left: 25px;
				font-size: 22px;
			}
			
			& p{
		    float: right;
				position: absolute;
				right: 16px;
				cursor: pointer;
				height: 50px;
				width: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				line-height: 3.25;
				margin: 0;
			}
		}
		&-body{
			height: 80%;
			background: #f8f8f8;
			overflow-y: scroll;
			padding: 12px;
			
			&-send{
			width: 250px;
    	float: right;
    	background: white;
    	padding: 10px 20px;
			border-radius: 5px;
			box-shadow: 0 0 10px rgba(0,0,0,.015);
			margin-bottom: 14px;
				& p{
					margin: 0;
					color: #444;
					font-size: 14px;
					margin-bottom: .25rem;
				}
				& span{
				float: right;
    		color: #777;
    		font-size: 10px;
				}
			}
			&-receive{
			width: 275px;
    	float: left;
    	background: white;
    	padding: 10px 20px;
			border-radius: 5px;
			box-shadow: 0 0 10px rgba(0,0,0,.015);
			margin-bottom: 14px;
				& p{
					margin: 0;
					color: #444;
					font-size: 14px;
					margin-bottom: .25rem;
				}
				& span{
				float: right;
    		color: #777;
    		font-size: 10px;
				}

				& div {
					float: right;
					margin-right: -50px;
				}
			}
			&::-webkit-scrollbar {
  			 width: 5px;
				opacity: 0;
			}
		}
	&-footer{
		position: relative;
		display: flex;
		align-items: center;
		
		& button{
		border: none;
    padding: 16px;
    font-size: 14px;
    background: white;
    cursor: pointer;
			&:focus{
				outline:none;
			}
		}

		& input{
    padding: 10px;
    border: none;
    -webkit-appearance: none;
    border-radius: 50px;
    background: whitesmoke;
    margin: 10px;
		font-size: 14px;
    font-weight: 600;
    color: #000;
    width: 100%;
			
			&:focus{
				outline: none;
			}
			
		}
		& .send{
    	vertical-align: middle;
    	align-items: center;
			margin-left: 8px;
			margin-right: 24px;
    	justify-content: center;
      // transform: translate(0px, 20px);
			cursor: pointer;
		}
	}
}

.chat-button{
	padding: 8px 16px;
	background: var(--theme-color);
	width: auto;
	position: fixed;
	bottom: 0;
  z-index: 999;
	right: 70px;
  color: white;
  font-weight: 600;
	margin: 15px;
	border-top-left-radius: 25px;
	border-top-right-radius: 25px;
	border-bottom-left-radius: 25px;
	/* box-shadow: ; */
	cursor: pointer;
	
	/* & span{

		&::before{
		content: '';
    height: 15px;
    width: 15px;
    background: #47cf73;
    position: absolute;
    transform: translate(0, -7px);
    border-radius: 15px;
		}
		
		&::after{
		content: "Message Us";
    font-size: 14px;
    color: white;
    position: absolute;
    left: 50px;
    top: 18px;
		}
	} */
}



		.chatmodal {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        transform: scale(1.1);
        // transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    }
    .chatmodal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 1rem 1.5rem;
        width: 24rem;
        border-radius: 0.5rem;
    }
    .chatmodal-close-button {
        float: right;
        width: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
        cursor: pointer;
        border-radius: 0.25rem;
        background-color: lightgray;
    }
    .close-button:hover {
        background-color: darkgray;
    }
    .show-chatmodal {
      opacity: 1;
      visibility: visible;
      transform: scale(1.0);
      // transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
			z-index: 30;
    }
	


@media only screen and (max-width: 450px) {
	.chat-box{
		min-width: 100% !important;
	}
}

@media only screen and (max-width: 449px) {
  .chat-box {
    margin-right: 0;
    right: 0;
  }
}

.chat-icon-system {
	  width: 30px;
    height: 30px;
    margin-left: 4px;
    background: url(/assets/images/logo_icon_red.png);
    background-size: contain;
    margin-bottom: 4px;
}