
/** testimonial-section **/

.testimonial-section{
  position: relative;
}

.testimonial-section .testimonial-block{
  position: relative;
  display: block;
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
}

.testimonial-section .testimonial-block .thumb-box{
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: 10px;
}

.testimonial-section .testimonial-block .thumb-box img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-section .testimonial-block h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
}

.testimonial-section .testimonial-block .designation{
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 22px;
  color: #A9A7B0;
  margin-bottom: 20px;
}

.testimonial-section .testimonial-block .rating li{
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #FFC334;
}

.testimonial-section .testimonial-block p{
  font-size: 18px;
  line-height: 34px;
  color: #A9A7B0;
}

.testimonial-section .owl-nav{
  position: absolute;
  left: 0px;
  top: 50%;
  width: 100%;
  margin-top: -30px;
}

.testimonial-section .owl-nav button{
  position: absolute;
  top: 0px;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 64px;
  background: #1E1E1E;
  text-align: center;
  font-size: 10px;
  border-radius: 50%;
  transition: all 500ms ease;
}

.testimonial-section .owl-nav button:hover{
  color: #1E1E1E;
}

.testimonial-section .owl-nav button.owl-prev{
  left: 0px;
}

.testimonial-section .owl-nav button.owl-next{
  right: 0px;
}


/** testimonial-style-two **/

.testimonial-style-two{
  position: relative;
}

.testimonial-style-two .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-style-two .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-style-two .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-block-one .inner-box{
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.08);
  padding: 45px 50px 50px 50px;
}

.testimonial-block-one .inner-box .rating li{
  position: relative;
  display: inline-block;
  float: left;
  font-size: 16px;
  margin-right: 7px;
  color: #FFBD1F;
}

.testimonial-block-one .inner-box p{
  margin-bottom: 30px;
}

.testimonial-block-one .inner-box .author-box{
  position: relative;
  display: block;
  padding: 10px 0px 10px 85px;
}

.testimonial-block-one .inner-box .author-box .thumb-box{
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.testimonial-block-one .inner-box .author-box .thumb-box img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-one .inner-box .author-box h4{
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.testimonial-block-one .inner-box .author-box .designation{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
}

.testimonial-block-one .inner-box .clients-logo{
  position: absolute;
  right: 50px;
  bottom: 70px;
}


/** testimonial-style-three **/

.testimonial-style-three{
  position: relative;
}

.testimonial-style-three .owl-carousel .owl-stage-outer{
  overflow: visible;
}

.testimonial-style-three .owl-carousel .owl-stage-outer .owl-item{
  opacity: 0;
}

.testimonial-style-three .owl-carousel .owl-stage-outer .owl-item.active{
  opacity: 1;
}

.testimonial-block-two .inner-box{
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  padding: 42px 40px 45px 40px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.08);
}

.testimonial-block-two .inner-box .author-box{
  position: relative;
  display: block;
  padding: 12px 0px 12px 85px;
  margin-bottom: 30px;
}

.testimonial-block-two .inner-box .author-box .thumb-box{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .author-box .thumb-box img{
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .author-box h4{
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}

.testimonial-block-two .inner-box .author-box .designation{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 20px;
}

.testimonial-block-two .inner-box p{
  margin-bottom: 35px;
}

.testimonial-block-two .inner-box .rating li{
  position: relative;
  display: inline-block;
  float: left;
  font-size: 16px;
  color: #FFC844;
  margin-right: 7px;
}

.testimonial-block-two .inner-box .clients-logo{
  position: absolute;
  right: 40px;
  bottom: 45px;
}

.testimonial-style-three .owl-dots{
  position: relative;
  display: block;
  text-align: center;
  margin-top: 50px;
}

.testimonial-style-three .owl-theme .owl-dots .owl-dot span{
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin: 0px 10px;
  cursor: pointer;
  background: rgba(255, 29, 29, 0.30);
  transition: all 500ms ease;
}

.testimonial-style-three .owl-theme .owl-dots .owl-dot span:hover,
.testimonial-style-three .owl-theme .owl-dots .owl-dot.active span{
  transform: scale(2);
}

.light-home .testimonial-block-one .inner-box{
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.light-home .testimonial-block-one .inner-box p{
  color: #A9A7B0;
}

.light-home .testimonial-block-one .inner-box .author-box h4{
  color: #fff;
}

.light-home .testimonial-block-one .inner-box .author-box .designation{
  color: #A9A7B0;
}

.testimonial-page-section{
  position: relative;
}

.testimonial-page-section .testimonial-block-one .inner-box{
  margin-bottom: 30px;
}




/** rtl-css **/

.rtl .testimonial-block-one .inner-box{
  text-align: right;
}

.rtl .testimonial-block-one .inner-box .rating li{
  float: right;
  margin-right: 0px;
  margin-left: 7px;
}

.rtl .testimonial-block-one .inner-box .clients-logo{
  right: inherit;
  left: 50px;
}

.rtl .testimonial-block-one .inner-box .author-box{
  padding-left: 0px;
  padding-right: 85px;
}

.rtl .testimonial-block-one .inner-box .author-box .thumb-box{
  left: inherit;
  right: 0px;
}

.rtl .testimonial-block-two .inner-box{
  text-align: right;
}

.rtl .testimonial-block-two .inner-box .author-box{
  padding-left: 0px;
  padding-right: 85px;
}

.rtl .testimonial-block-two .inner-box .author-box .thumb-box{
  left: inherit;
  right: 0px;
}



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .testimonial-block-one .inner-box .clients-logo{
    position: relative;
    right: 0px;
    bottom: 0px;
    margin-bottom: 30px;
  }

  .rtl .testimonial-block-one .inner-box .clients-logo{
    left: 0px;
    text-align: right;
    display: inline-block;
  }
}

@media only screen and (max-width: 767px){
  .testimonial-section{
    padding-bottom: 70px;
  }

  .testimonial-section .owl-nav{
    display: none;
  }

  .testimonial-style-three .owl-dots{
    display: none;
  }

  .testimonial-style-three{
    padding-bottom: 70px;
  }

  .testimonial-style-two.home-7{
    padding-bottom: 70px;
  }

  .testimonial-style-two.home-8{
    padding: 70px 0px;
  }

  .testimonial-style-two.about-page{
    padding-top: 70px;
  }

  .testimonial-page-section{
    padding-bottom: 40px;
    padding-top: 70px;
  }
}

@media only screen and (max-width: 599px){
  .testimonial-block-two .inner-box .clients-logo{
    position: relative;
    right: 0px;
    bottom: 0px;
    margin-top: 15px;
  }
}

@media only screen and (max-width: 499px){
  .testimonial-block-one .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }

  .testimonial-block-two .inner-box{
    padding-left: 30px;
    padding-right: 30px;
  }
}









































