
/** news-section **/

.news-section{
  position: relative;
}

.news-block-one .inner-box{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
}

.news-block-one .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
}

.news-block-one .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-one .inner-box .lower-content{
  position: relative;
  display: block;
  padding: 25px 30px 35px 30px;
}

.news-block-one .inner-box .lower-content h6{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 11px;
}

.news-block-one .inner-box .lower-content h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 3px;
}

.news-block-one .inner-box .lower-content h3 a{
  position: relative;
  display: inline-block;
  color: #111;
}

.news-block-one .inner-box .lower-content h3 a:hover{

}

.news-block-one .inner-box .lower-content .post-info{
  position: relative;
  display: flex;
  align-items: center;
}

.news-block-one .inner-box .lower-content .post-info li{
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  color: #111;
  margin-right: 26px;
}

.news-block-one .inner-box .lower-content .post-info li:before{
  position: absolute;
  content: '';
  background: #BBBBBB;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 10px;
  right: -15px;
}

.news-block-one .inner-box .lower-content .post-info li:last-child:before{
  display: none;
}

.news-block-one .inner-box .lower-content .post-info li:last-child{
  margin: 0px !important;
}

.news-block-one .inner-box .lower-content .post-info li a{
  position: relative;
  display: inline-block;
  color: #111;
}

.news-block-one .inner-box .lower-content .post-info li a:hover{
  
}


/** news-style-two **/

.news-style-two{
  position: relative;
}

.news-block-two .inner-box{
  position: relative;
  display: block;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
}

.news-block-two .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
}

.news-block-two .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.news-block-two .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-two .inner-box .lower-content{
  position: relative;
  display: block;
  padding: 35px 30px;
}

.news-block-two .inner-box .lower-content .date-box{
  position: absolute;
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 50%;
  top: -35px;
  right: 30px;
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  padding: 10px 0px;
}

.news-block-two .inner-box .lower-content .date-box span{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.news-block-two .inner-box .lower-content .post-info{
  position: relative;
  display: flex;
  align-items: center;
}

.news-block-two .inner-box .lower-content .post-info .sub-title{
  margin-bottom: 0px;
}

.news-block-two .inner-box .lower-content .post-info li{
  font-size: 16px;
  line-height: 26px;
  color: #111;
  margin-right: 20px;
}

.news-block-two .inner-box .lower-content .post-info li:last-child{
  margin: 0px !important;
}

.news-block-two .inner-box .lower-content .post-info li a{
  display: inline-block;
  color: #111;
  font-weight: 600;
}

.news-block-two .inner-box .lower-content .post-info li a:hover{

}

.news-block-two .inner-box .lower-content h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 17px;
}

.news-block-two .inner-box .lower-content h3 a{
  display: inline-block;
  color: var(--title-color);
}

.news-block-two .inner-box .lower-content h3 a:hover{

}


/** news-style-three **/

.news-style-three{
  position: relative;
}

.news-block-three .inner-box{
  position: relative;
  display: block;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}

.news-block-three .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
}

.news-block-three .inner-box .image-box img{
  width: 100%;
  transition: all 500ms ease;
}

.news-block-three .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-three .inner-box .content-box{
  position: relative;
  display: block;
  padding: 34px 30px 29px 30px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
}

.news-block-three .inner-box .content-box .date-box{
  position: absolute;
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 50%;
  bottom: -35px;
  right: 30px;
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  padding: 10px 0px;
  z-index: 1;
}

.news-block-three .inner-box .content-box .date-box span{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}

.news-block-three .inner-box .content-box h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 17px;
}

.news-block-three .inner-box .content-box h3 a{
  display: inline-block;
  color: #fff;
}

.news-block-three .inner-box .content-box h3 a:hover{

}

.news-block-three .inner-box .content-box p{
  color: #A9A7B0;
  margin-bottom: 18px;
}

.news-block-three .inner-box .content-box .post-info li{
  position: relative;
  display: inline-block;
  float: left;
  font-size: 16px;
  margin-right: 30px;
  color: #A9A7B0;
}

.news-block-three .inner-box .content-box .post-info li:first-child{

}

.news-block-three .inner-box .content-box .post-info li:last-child{
  margin: 0px !important;
}

.news-block-three .inner-box .content-box .post-info li a{
  display: inline-block;
  font-weight: 600;
  color: #A9A7B0;
}

.news-block-three .inner-box .content-box .post-info li a:hover{

}

/** news-style-four **/

.news-style-four{
  position: relative;
}

.news-block-four .inner-box{
  position: relative;
  display: block;
  margin-bottom: 50px;
}

.news-block-four .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.news-block-four .inner-box .image-box img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.news-block-four .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-four .inner-box .lower-content{
  position: relative;
  display: block;
  padding-top: 35px;
}

.news-block-four .inner-box .lower-content .date-box{
  position: absolute;
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 50%;
  top: -35px;
  right: 30px;
  font-size: 22px;
  line-height: 30px;
  color: #111;
  font-weight: 700;
  padding: 10px 0px;
  z-index: 1;
}

.news-block-four .inner-box .lower-content .date-box span{
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.news-block-four .inner-box .lower-content .sub-title{
  text-transform: capitalize;
  margin-bottom: 0px;
}

.news-block-four .inner-box .lower-content .post-info{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.news-block-four .inner-box .lower-content .post-info li{
  position: relative;
  display: inline-block;
  margin-right: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #A9A7B0;
}

.news-block-four .inner-box .lower-content .post-info li:last-child{
  margin: 0px !important;
}

.news-block-four .inner-box .lower-content .post-info li a{
  display: inline-block;
  color: #A9A7B0;
}

.news-block-four .inner-box .lower-content .post-info li a:hover{

}

.news-block-four .inner-box .lower-content h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
}

.news-block-four .inner-box .lower-content h3 a{
  display: inline-block;
  color: #fff;
}

.news-block-four .inner-box .lower-content h3 a:hover{

}

.news-block-four .inner-box .lower-content h2{
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 56px;
}

.news-block-four .inner-box .lower-content h2 a{
  display: inline-block;
  color: #fff;
}

.news-block-four .inner-box .lower-content h2 a:hover{

}


/** news-style-five **/

.news-style-five{
  position: relative;
}

.news-block-five .inner-box{
  position: relative;
  display: block;
  padding-left: 299px;
  align-items: center;
  min-height: 175px;
}

.news-block-five .inner-box .image-box{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 299px;
  border-radius: 10px;
  overflow: hidden;
}

.news-block-five .inner-box .image-box img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.news-block-five .inner-box:hover .image-box img{
  transform: scale(1.05);
}

.news-block-five .inner-box .content-box{
  position: relative;
  display: block;
  padding: 34px 0px 34px 30px;
}

.news-block-five .inner-box .content-box .sub-title{
  text-transform: capitalize;
  margin-bottom: 0px;
}

.news-block-five .inner-box .content-box .post-info{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.news-block-five .inner-box .content-box .post-info li{
  position: relative;
  display: inline-block;
  margin-right: 20px;
  font-size: 16px;
  line-height: 26px;
  color: #A9A7B0;
}

.news-block-five .inner-box .content-box .post-info li:last-child{
  margin: 0px !important;
}

.news-block-five .inner-box .content-box .post-info li a{
  display: inline-block;
  color: #A9A7B0;
}

.news-block-five .inner-box .content-box .post-info li a:hover{

}

.news-block-five .inner-box .content-box h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
}

.news-block-five .inner-box .content-box h3 a{
  display: inline-block;
  color: #fff;
}


.sidebar-page-container{
  position: relative;
}

.news-block-two .inner-box .lower-content h2{
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 40px;
  margin-bottom: 15px;
}

.news-block-two .inner-box .lower-content h2 a{
  position: relative;
  display: inline-block;
  color: #111;
}

.news-block-two .inner-box .lower-content h2 a:hover{

}

.news-block-two .inner-box .lower-content .link a{
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-family: var(--title-font);
  font-weight: 500;
  color: var(--title-color);
}

.news-block-two .inner-box .lower-content .link a:hover{

}

.news-block-two .inner-box .lower-content .link a i{
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  top: 1px;
  margin-left: 8px;
}

.sidebar-page-container .news-block-two .inner-box{
  margin-bottom: 60px;
}




/** rtl-css **/

.rtl .news-block-one .inner-box .lower-content .post-info li{
  margin-right: 0px;
  margin-left: 26px;
}

.rtl .news-block-one .inner-box .lower-content .post-info li:before{
  right: inherit;
  left: -15px;
}

.rtl .news-block-two .inner-box .lower-content .post-info li{
  margin-right: 0px;
  margin-left: 20px;
}

.rtl .news-block-two .inner-box .lower-content .date-box{
  right: inherit;
  left: 30px;
}

.rtl .news-block-three .inner-box .content-box .post-info li{
  float: right;
  margin-right: 0px;
  margin-left: 30px;
}

.rtl .news-block-three .inner-box .content-box .date-box{
  right: inherit;
  left: 30px;
}

.rtl .news-block-four .inner-box .lower-content .date-box{
  right: inherit;
  left: 30px;
}

.rtl .news-block-four .inner-box .lower-content .post-info li{
  margin-right: 0px;
  margin-left: 20px;
}

.rtl .news-block-five .inner-box .content-box .post-info li{
  margin-right: 0px;
  margin-left: 20px;
}

.rtl .news-block-two .inner-box .lower-content .link a i{
  margin-left: 0px;
  margin-right: 8px;
}

/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){
  .news-block-one .inner-box{
    margin-bottom: 30px;
  }

  .news-section{
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px){
  .news-section{
    padding-bottom: 40px;
  }

  .news-style-two{
    padding: 70px 0px 40px 0px;
  }

  .news-style-three{
    padding-bottom: 40px;
  }

  .news-style-four{
    padding: 70px 0px 20px 0px;
  }

  .news-style-five{
    padding: 70px 0px;
  }

  .news-style-six{
    padding-bottom: 20px;
  }

  .sidebar-page-container{
    padding: 70px 0px;
  }
}

@media only screen and (max-width: 599px){
  .news-block-four .inner-box .lower-content h2{
    font-size: 24px;
    line-height: 34px;
  }

  .news-block-five .inner-box{
    padding-left: 0px;
  }

  .news-block-five .inner-box .image-box{
    position: relative;
    width: 100%;
  }

  .news-block-five .inner-box .content-box{
    padding: 35px 0px 0px 0px;
  }
}

@media only screen and (max-width: 499px){
  .news-block-two .inner-box .lower-content .post-info{
    display: block;
  }

  .news-block-two .inner-box .lower-content h2{
    font-size: 26px;
    line-height: 34px;
  }
}









































