
/** team-section **/

.team-section{
  position: relative;
}

.team-block-one .inner-box{
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.team-block-one .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
}

.team-block-one .inner-box .image-box .image{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.team-block-one .inner-box .image-box .image:before{
  position: absolute;
  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border: 1px solid #EAEAEA;
  border-radius: 10px;
  z-index: 1;
}

.team-block-one .inner-box .image-box .image img{
  width: 100%;
  border-radius: 10px;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .image img{
  transform: scale(1.05);
}

.team-block-one .inner-box .image-box .video-btn{
  position: absolute;
  left: 20px;
  bottom: 12px;
  z-index: 2;
}

.team-block-one .inner-box .image-box .video-btn a{
  position: relative;
  display: inline-block;
  width: 56px;
  height: 56px;
  line-height: 56px;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
  z-index: 1;
  border: 1px solid rgba(255, 255, 255, 0.50);
}

.team-block-one .inner-box .image-box .video-btn a:before{
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  filter: blur(10px);
  opacity: 0.2;
  z-index: -1;
}

.team-block-one .inner-box .lower-content{
  position: relative;
  display: block;
  padding-top: 19px;
}

.team-block-one .inner-box .lower-content h3{
  font-size: 24px;
  line-height: 26px;
  font-weight: 500;
}

.team-block-one .inner-box .lower-content h3 a{
  position: relative;
  display: inline-block;
  color: var(--title-color);
}

.team-block-one .inner-box .lower-content h3 a:hover{

}

.team-block-one .inner-box .lower-content span{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
}


/** team-style-two **/

.team-style-two{
  position: relative;
}

.team-block-two .inner-box{
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.team-block-two .inner-box .image-box{
  position: relative;
  display: block;
  overflow: hidden;
}

.team-block-two .inner-box .image-box .image{
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.team-block-two .inner-box .image-box .image img{
  width: 100%;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .image img{
  transform: scale(1.05);
}

.team-block-two .inner-box .image-box .social-links{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%) scale(0,0);
  width: 100%;
  text-align: center;
  z-index: 1;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box .social-links{
  transform: translate(-50%,-50%) scale(1,1);
}

.team-block-two .inner-box .image-box .social-links li{
  position: relative;
  display: inline-block;
  margin: 0px 3px;
}

.team-block-two .inner-box .image-box .social-links li a{
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  line-height: 46px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #111;
}

.team-block-two .inner-box .image-box .social-links li a:hover{

}

.team-block-two .inner-box .lower-content{
  position: relative;
  padding-top: 15px;
}

.team-block-two .inner-box .lower-content h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0px;
}

.team-block-two .inner-box .lower-content h3 a{
  display: inline-block;
  color: #fff;
}

.team-block-two .inner-box .lower-content h3 a:hover{

}

.team-block-two .inner-box .lower-content .designation{
  position: relative;
  display: block;
  color: #A9A7B0;
}

.team-style-two.team-page .team-block-two .inner-box .lower-content h3 a{
  color: #111;
}

.team-style-two.team-page .team-block-two .inner-box .lower-content .designation{
  color: rgba(106, 106, 106, 1);
}

.team-style-two.team-page .team-block-two .inner-box .image-box .social-links li a:hover{
  color: #fff;
}


/** rtl-css **/



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .team-section{
    padding: 70px 0px 30px 0px;
  }

  .team-style-two{
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 599px){
  
}

@media only screen and (max-width: 499px){
  
}









































