
/** service-section **/

.service-section{
  position: relative;
}

.service-block-one{
  margin-top: 35px;
}

.service-block-one .inner-box{
  position: relative;
  display: block;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 10px;
  padding: 0px 40px 40px 40px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
  transition: all 500ms ease;
}

.service-block-one .inner-box:hover{
  transform: translateY(-10px);
}

.service-block-one .inner-box .icon-box{
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 35px;
  color: #fff;
  background: #B47AFF;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 25px;
  margin-top: -30px;
  top: -5px;
}

.service-block:nth-child(2) .service-block-one .inner-box .icon-box{
  background: #37BB7D;
}

.service-block:nth-child(3) .service-block-one .inner-box .icon-box{
  background: #FA8747;
}

.service-block:nth-child(4) .service-block-one .inner-box .icon-box{
  background: #FF6A6A;
}

.service-block:nth-child(5) .service-block-one .inner-box .icon-box{
  background: #02CECE;
}

.service-block:last-child .service-block-one .inner-box .icon-box{
  background: #7E69FF;
}


.service-block-one .inner-box h3{
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin-bottom: 15px;
}

.service-block-one .inner-box h3 a{
  display: inline-block;
  color: #111111;
}

.service-block-one .inner-box h3 a:hover{

}


/** service-style-two **/

.service-style-two{
  position: relative;
}

.service-style-two .service-block-one .inner-box{
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.service-style-two .service-block-one .inner-box h3 a{
  color: #fff;
}

.service-style-two .service-block-one .inner-box h3 a:hover{

}

.service-style-two .service-block-one .inner-box p{
  color: #A9A7B0;
}

.service-style-two .pattern-layer{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
}


/** rtl-css **/



/** responsive-css **/

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 991px){

}

@media only screen and (max-width: 767px){
  .service-section{
    padding-bottom: 40px;
  }

  .service-style-two{
    padding: 70px 0px 40px 0px;
  }

  .service-section.service-page{
    padding-top: 70px;
  }
}

@media only screen and (max-width: 599px){
  
}

@media only screen and (max-width: 499px){
  
}









































